.header {
  .header();
  border-top: 13px solid #cecccc;
  box-shadow: 0 3px 5px #858585;
  z-index: 15;
  #logo {
    padding: 30px 0;
  }
  .lang-selector {
    margin-top: 40px;
  }
}

.footer {
  max-height: 100%;
  text-align: center;
  border-top: 10px solid #5d5d5d;
  color: #a1a1a1;
  .wrapper {
    .clearfix();
  }
  .disclaimer {
    padding: 28px 0;
    font-size: 0.75em;
    color: #8e8e8e;
    line-height: 1.5em;
    background: #6c6c6c;
  }
  .copyright {
    background: #505050;
    color: #d9d9d9;
    text-align: center;
    font-size: 0.875em;
    padding: 20px 0;
  }
}