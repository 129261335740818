@import url(https://fonts.googleapis.com/css?family=Lato:400,700,300);
@import "font-awesome.min.css";
.header {
  background: #fff;
  position: relative;
  border-top: 13px solid #cecccc;
  box-shadow: 0 3px 5px #858585;
  z-index: 15;
}
.header #logo {
  float: left;
}
.header #logo img {
  max-width: 100%;
}
.header .lang-selector {
  float: right;
  background: rgba(15, 15, 15, 0.3);
  border-radius: 3px;
  font-size: 0.7em;
  text-transform: uppercase;
  cursor: pointer;
  margin-left: 10px;
  margin-top: 12px;
  position: relative;
}
.header .lang-selector a {
  color: #fff;
  display: block;
  padding: 3px 10px;
}
.header #languages {
  position: absolute;
  top: 100%;
  right: 0px;
  display: none;
  background: rgba(15, 15, 15, 0.3);
  border-radius: 3px;
  color: #fff;
  font-size: 1em;
  text-transform: uppercase;
  padding: 3px 10px;
  text-align: right;
  z-index: 100;
  min-width: 100%;
}
.header #languages a {
  color: #fff;
}
.header #logo {
  padding: 30px 0;
}
.header .lang-selector {
  margin-top: 40px;
}
.footer {
  max-height: 100%;
  text-align: center;
  border-top: 10px solid #5d5d5d;
  color: #a1a1a1;
}
.footer .wrapper {
  *zoom: 1;
}
.footer .wrapper:before,
.footer .wrapper:after {
  content: "";
  display: table;
}
.footer .wrapper:after {
  clear: both;
}
.footer .disclaimer {
  padding: 28px 0;
  font-size: 0.75em;
  color: #8e8e8e;
  line-height: 1.5em;
  background: #6c6c6c;
}
.footer .copyright {
  background: #505050;
  color: #d9d9d9;
  text-align: center;
  font-size: 0.875em;
  padding: 20px 0;
}
body {
  width: 100%;
  *zoom: 1;
}
body:before,
body:after {
  content: "";
  display: table;
}
body:after {
  clear: both;
}
*,
*:after,
*:before {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
html,
body {
  height: 100%;
}
body {
  color: #505050;
  font: 400 100%/1.6 'Lato', Helvetica, Arial, sans-serif;
  background: #505050;
}
@media only screen and (max-width: 768px) {
  body {
    font-size: 85%;
  }
}
.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}
a {
  color: #329ad7;
  text-decoration: none;
}
a:hover {
  color: #237eb3;
}
button,
a.button {
  background: #329ad7;
  color: #fff;
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  border-radius: 4px;
  font-size: 1em;
  padding: 8px 25px;
  margin: 10px 0 20px;
  border: 0 none;
  cursor: pointer;
}
a.button:hover {
  background: #237eb3;
}
a.button.large {
  font-size: 1.3em;
  padding: 12px 40px;
}
button.facebook,
.button.facebook,
input[type=submit].facebook,
button.google,
.button.google,
input[type=submit].google,
button.twitter,
.button.twitter,
input[type=submit].twitter {
  font-size: 1em;
}
button.facebook .fa,
.button.facebook .fa,
input[type=submit].facebook .fa,
button.google .fa,
.button.google .fa,
input[type=submit].google .fa,
button.twitter .fa,
.button.twitter .fa,
input[type=submit].twitter .fa {
  margin: 0 5px;
}
button.facebook,
.button.facebook,
input[type=submit].facebook {
  background: #3b5998;
}
button.facebook:hover,
.button.facebook:hover,
input[type=submit].facebook:hover {
  background: #2d4373;
}
button.google,
.button.google,
input[type=submit].google {
  background: #c32f10;
}
button.google:hover,
.button.google:hover,
input[type=submit].google:hover {
  background: #94240c;
}
button.twitter,
.button.twitter,
input[type=submit].twitter {
  background: #00aced;
}
button.twitter:hover,
.button.twitter:hover,
input[type=submit].twitter:hover {
  background: #0087ba;
}
button.danger,
.button.danger,
input[type=submit].danger {
  background: #C40E0E;
}
button.danger:hover,
.button.danger:hover,
input[type=submit].danger:hover {
  background: #940b0b;
}
button.success,
.button.success,
input[type=submit].success {
  background: #2ecc71;
}
button.success:hover,
.button.success:hover,
input[type=submit].success:hover {
  background: #25a25a;
}
input,
select,
textarea {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
}
img {
  max-width: 100%;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.clear {
  clear: both;
}
.messages {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 130;
}
.alert {
  padding: 13px;
  color: #fff;
  margin-bottom: 15px;
  border-radius: 4px;
}
.alert p {
  font-size: 0.875em;
}
.alert p a {
  text-decoration: underline;
  color: #fff;
}
.alert.alert-error {
  background: #C40E0E;
  border: 1px solid #940b0b;
}
.alert.alert-success {
  background: #2ecc71;
  border: 1px solid #25a25a;
}
.alert.hide-after-load {
  animation: animation-hide 0s ease-in 10s forwards;
  animation-fill-mode: forwards;
}
@keyframes animation-hide {
  to {
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
  }
}
.success-label {
  color: #2ecc71;
}
.failure-label {
  color: #C40E0E;
}
.txt-center {
  text-align: center;
}
.txt-right {
  text-align: right;
}
.form {
  background: #fff;
  padding: 20px;
}
.form h1 {
  text-align: center;
  font-size: 2em;
  font-weight: 300;
  margin-bottom: 10px;
  margin-top: 10px;
}
.form h2 {
  text-align: center;
  font-weight: 400;
}
.form .form-group {
  padding: 30px 30px 10px;
}
.form-group {
  margin: 0 auto;
  border-top: 1px solid #d7d7d7;
}
.form-group label,
.form-group input[type="text"],
.form-group input[type="password"],
.form-group input[type="submit"],
.form-group select,
.form-group select option {
  display: block;
}
.form-group label {
  font-size: 0.75em;
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: 2px;
}
.form-group label.terms,
.form-group label.radio {
  display: inline-block;
  font-weight: 400;
}
.form-group label.radio {
  font-size: 1em;
}
.form-group input[type="text"],
.form-group input[type="password"],
.form-group select,
.form-group select option {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.form-group input[type="text"].error,
.form-group input[type="password"].error,
.form-group select.error,
.form-group select option.error {
  border: 1px solid #C40E0E;
}
.form-group input[type="text"].error:focus,
.form-group input[type="password"].error:focus,
.form-group select.error:focus,
.form-group select option.error:focus {
  border: 1px solid #d7d7d7;
}
.form-group select {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAh0lEQVQ4T93TMQrCUAzG8V9x8QziiYSuXdzFC7h4AcELOPQAdXYovZCHEATlgQV5GFTe1ozJlz/kS1IpjKqw3wQBVyy++JI0y1GTe7DCBbMAckeNIQKk/BanALBB+16LtnDELoMcsM/BESDlz2heDR3WePwKSLo5eoxz3z6NNcFD+vu3ij14Aqz/DxGbKB7CAAAAAElFTkSuQmCC) no-repeat center right;
}
.form-group .error-msg {
  font-size: 0.75em;
  color: #C40E0E;
}
.form-group #submit {
  background: #329ad7;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  border-radius: 4px;
  font-size: 1.25em;
  padding: 12px 45px;
  margin: 20px 0 30px;
  border: 0 none;
  cursor: pointer;
}
.form-group #submit:hover {
  background: #237eb3;
}
.form-group p {
  font-size: 0.875em;
  margin-top: 10px;
}
.form-group a.button {
  display: inline-block;
}
.form-group.or {
  margin-top: 20px;
  padding: 20px 30px 10px;
  text-align: center;
  position: relative;
}
.form-group.or:after {
  background: #fff;
  position: absolute;
  top: -27px;
  left: 50%;
  content: "OR";
  font-weight: 300;
  font-size: 2em;
  width: 80px;
  text-align: center;
  margin-left: -40px;
}
.form-group.social-buttons {
  padding-left: 0;
  padding-right: 0;
}
.form-group.social-buttons .button {
  padding: 12px;
  white-space: nowrap;
}
.form-page {
  max-width: 450px;
  margin: 0 auto;
  *zoom: 1;
}
.form-page:before,
.form-page:after {
  content: "";
  display: table;
}
.form-page:after {
  clear: both;
}
#content {
  background: url(../img/bg.jpg) no-repeat center center;
  background-size: cover;
  padding: 60px 0;
}
.login-page {
  max-width: 450px;
  margin: 0 auto;
  *zoom: 1;
}
.login-page:before,
.login-page:after {
  content: "";
  display: table;
}
.login-page:after {
  clear: both;
}
.login-page .form .signup {
  clear: both;
}
.signup-page {
  *zoom: 1;
  background: #f1f0f0;
}
.signup-page:before,
.signup-page:after {
  content: "";
  display: table;
}
.signup-page:after {
  clear: both;
}
.signup-page .info {
  display: inline;
  float: left;
  width: 33.33333333%;
  margin: 0 0%;
  padding: 30px;
}
.signup-page .info h2 {
  font-size: 1.25em;
  margin-bottom: 10px;
}
.signup-page .info ul {
  list-style: none;
  margin-bottom: 30px;
}
.signup-page .info ul li {
  margin-bottom: 10px;
  font-size: 1.125em;
}
.signup-page .info ul li i {
  color: #329ad7;
  margin-right: 5px;
}
.signup-page .info img {
  margin-top: 20px;
}
.signup-page .info img.devices {
  margin-top: 50px;
}
.signup-page .form {
  display: inline;
  float: left;
  width: 66.66666667%;
  margin: 0 0%;
}
.signup-page .form .form-group {
  max-width: 600px;
  margin: 30px auto 0px;
}
.account-page {
  *zoom: 1;
  background: #505050;
}
.account-page:before,
.account-page:after {
  content: "";
  display: table;
}
.account-page:after {
  clear: both;
}
.account-page .user {
  display: inline;
  float: left;
  width: 25%;
  margin: 0 0%;
  color: #fff;
}
@media only screen and (max-width: 920px) {
  .account-page .user {
    display: inline;
    float: left;
    width: 100%;
    margin: 0 0%;
  }
}
.account-page .user .avatar {
  margin: 30px 20px 20px 30px;
  border-radius: 100%;
  border: 3px solid #9d9d9d;
  float: left;
}
@media only screen and (max-width: 920px) {
  .account-page .user .avatar {
    width: 50px;
    margin: 10px 10px 10px 10px;
  }
}
.account-page .user h2 {
  float: left;
  font-size: 1.25em;
  margin-bottom: 10px;
  margin-top: 65px;
}
@media only screen and (max-width: 920px) {
  .account-page .user h2 {
    font-size: 1em;
    margin-top: 22px;
  }
}
.account-page .user #toggleMenu {
  display: none;
  color: #fff;
  float: right;
  padding: 2px 10px 3px;
  border: 1px solid #838383;
  margin: 20px 15px 10px;
  border-radius: 2px;
}
@media only screen and (max-width: 920px) {
  .account-page .user #toggleMenu {
    display: inline-block;
  }
}
@media only screen and (max-width: 920px) {
  .account-page .user .menu {
    display: none;
  }
}
.account-page .user ul {
  border-top: 1px solid #6a6a6a;
  list-style: none;
  margin-bottom: 30px;
}
@media only screen and (max-width: 920px) {
  .account-page .user ul {
    margin-bottom: 0px;
  }
}
.account-page .user ul li {
  font-size: 1.125em;
  border-bottom: 1px solid #6a6a6a;
}
.account-page .user ul li a {
  color: #fff;
  padding: 7px 30px 7px;
  display: block;
  font-size: 0.875em;
}
.account-page .user ul li a:hover {
  color: #329ad7;
}
.account-page .user ul li a.active {
  color: #329ad7;
}
.account-page .user ul li span {
  width: 30px;
  display: inline-block;
  text-align: center;
}
.account-page .user ul li i {
  margin-right: 10px;
}
.account-page .account-content {
  background: #fff;
  display: inline;
  float: left;
  width: 75%;
  margin: 0 0%;
  padding: 20px;
  min-height: 480px;
}
@media only screen and (max-width: 920px) {
  .account-page .account-content {
    display: inline;
    float: left;
    width: 100%;
    margin: 0 0%;
  }
}
.account-page .account-content h1 {
  font-size: 2em;
  font-weight: 300;
  margin-bottom: 10px;
  margin-top: 10px;
}
.account-page .account-content h2 {
  font-weight: 400;
}
.account-page .account-content h3 {
  margin-bottom: 10px;
  font-size: 0.875em;
  margin-top: 10px;
}
.account-page .account-content .left,
.account-page .account-content .right {
  display: inline;
  float: left;
  width: 50%;
  margin: 0 0%;
}
@media only screen and (max-width: 920px) {
  .account-page .account-content .left,
  .account-page .account-content .right {
    display: inline;
    float: left;
    width: 100%;
    margin: 0 0%;
  }
}
.account-page .account-content .left .form-group {
  padding-right: 20px;
}
@media only screen and (max-width: 920px) {
  .account-page .account-content .left .form-group {
    padding-right: 0;
  }
}
.account-page .account-content .right .form-group {
  padding-left: 20px;
}
@media only screen and (max-width: 920px) {
  .account-page .account-content .right .form-group {
    padding-left: 0;
    border-top: 0 none;
  }
}
.account-page .account-content .form-group.last {
  border: 0 none;
}
.account-page .account-content .form-group.full input[type="text"],
.account-page .account-content .form-group.full input[type="password"],
.account-page .account-content .form-group.full select,
.account-page .account-content .form-group.full select option {
  max-width: 50%;
}
.account-page .account-content #submit.delete {
  background: #C40E0E;
}
.account-page .account-content .half {
  width: 50%;
  padding-right: 10px;
  float: left;
  margin-bottom: 15px;
}
.account-page .account-content .half.last {
  padding-right: 0;
  padding-left: 10px;
}
.account-page .account-content .account-table {
  width: 100%;
  margin-bottom: 20px;
}
@media only screen and (max-width: 920px) {
  .account-page .account-content .account-table {
    font-size: 0.9em;
  }
}
.account-page .account-content .account-table thead {
  background: #505050;
  color: #fff;
}
.account-page .account-content .account-table th {
  font-weight: 700;
  border: 1px solid #505050;
}
.account-page .account-content .account-table td {
  font-size: 0.875em;
  border: 1px solid #bebdbd;
  vertical-align: middle;
}
.account-page .account-content .account-table th,
.account-page .account-content .account-table td {
  text-align: center;
  padding: 10px 5px;
}
.account-page .account-content .account-table tr:nth-of-type(even) td {
  background: #f1f0f0;
}
.account-page .account-content .account-table .view {
  background: #329ad7;
  border-radius: 20px;
  color: #fff;
  font-size: 0.875em;
  padding: 4px 10px;
  white-space: nowrap;
}
.account-page .account-content .dashboard h1 {
  border-bottom: 1px solid #d7d7d7;
}
.account-page .account-content .dashboard .subtitle {
  margin-top: 10px;
  font-size: 1.25em;
}
.account-page .account-content .dashboard .left {
  padding-right: 10px;
}
.account-page .account-content .dashboard .right {
  padding-left: 10px;
}
@media only screen and (max-width: 920px) {
  .account-page .account-content .dashboard .left,
  .account-page .account-content .dashboard .right {
    padding: 0;
  }
}
.account-page .account-content .dashboard p {
  margin-top: 8px;
}
.account-page .account-content .dashboard div.help {
  width: 100%;
  background: url(../img/arrow.jpg) no-repeat 110px 10px;
}
.account-page .account-content .dashboard h2.help {
  margin-top: 20px;
  font-weight: bold;
  font-size: 1.6em;
  margin-bottom: 10px;
}
.account-page .account-content .dashboard p.help {
  max-width: 450px;
  margin: 0 auto;
  margin-bottom: 20px;
}
.account-page .account-content .dashboard .summary {
  width: 100%;
  margin: 15px 0;
  border-collapse: collapse;
}
.account-page .account-content .dashboard .summary td,
.account-page .account-content .dashboard .summary th {
  padding: 5px 8px;
}
.account-page .account-content .dashboard .summary thead th {
  background: #505050;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: bold;
}
.account-page .account-content .dashboard .summary thead th:first-child {
  border: none;
}
.account-page .account-content .dashboard .summary tbody td {
  border-left: 1px solid #e6e6e6;
  font-size: 12px;
  font-weight: normal;
}
.account-page .account-content .dashboard .summary tbody tr:nth-of-type(even) td {
  background: #f5f5f5;
}
.account-page .account-content .dashboard .summary tbody td:first-child {
  border-left: none;
}
.account-page .account-content .dashboard .summary tbody tr:last-child td {
  border-bottom: none;
}
.account-page .account-content .subscriptions .activate {
  display: flex;
}
.account-page .account-content .subscriptions .activate a {
  text-align: center;
  background: #329ad7;
  color: #fff;
  border-radius: 3px;
  padding: 5px 15px;
}
.account-page .account-content .subscriptions .activate a:hover {
  background: #237eb3;
}
.account-page .account-content .subscriptions .activate.not-active {
  justify-content: center;
}
.account-page .account-content .subscriptions .activate.not-active a {
  font-size: 2rem;
}
.account-page .account-content .devices .add {
  display: flex;
}
.account-page .account-content .devices .add a {
  text-align: center;
  background: #329ad7;
  color: #fff;
  border-radius: 3px;
  padding: 5px 15px;
}
.account-page .account-content .devices .add a:hover {
  background: #237eb3;
}
.text-page {
  background: #fff;
  padding: 20px;
  max-width: 650px;
  margin: 0 auto;
}
.text-page h1 {
  text-align: center;
  font-size: 2em;
  font-weight: 300;
  margin-bottom: 10px;
  margin-top: 10px;
}
.text-page h2 {
  text-align: center;
  font-weight: 400;
  margin-bottom: 20px;
}
.text-page p {
  font-size: 0.875em;
  margin-bottom: 1.6em;
}
.text-page ul {
  margin-left: 2em;
}
